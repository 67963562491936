import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "filters" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "rounded-lg dataBody auto-height pl-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "pl-3 pr-3 rounded-lg dataBody" }
const _hoisted_6 = { class: "pl-3 pr-3 rounded-lg dataBody" }
const _hoisted_7 = { class: "pl-3 pr-3 rounded-lg dataBody" }
const _hoisted_8 = { class: "pl-3 pr-3 rounded-lg dataBody" }
const _hoisted_9 = { class: "totalcalc" }
const _hoisted_10 = { class: "totalcalc" }
const _hoisted_11 = { class: "totalcalc" }
const _hoisted_12 = { class: "text-subtitle-1" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { class: "text-h6" }
const _hoisted_15 = { class: "text-subtitle-1" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "emptyResult d-flex justify-center mb-6"
}
const _hoisted_18 = { class: "pa-md-16 ma-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRange = _resolveComponent("DateRange")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_ProfileAvatar = _resolveComponent("ProfileAvatar")!
  const _component_Pie = _resolveComponent("Pie")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_weekly_performance_report = _resolveComponent("weekly-performance-report")!
  const _component_SighteFormsScript = _resolveComponent("SighteFormsScript")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_v_card, {
        loading: _ctx.loading,
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                md: "4",
                sm: "12",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DateRange, {
                    offset: _ctx.dayOffset,
                    onFetchData: _ctx.fetchData
                  }, null, 8, ["offset", "onFetchData"])
                ]),
                _: 1
              }),
              (_ctx.filterItems.length)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    md: "8",
                    sm: "12",
                    xs: "12",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("span", null, [
                          _cache[1] || (_cache[1] = _createTextVNode(" Filters: ")),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterItems, (tag) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: tag.title,
                              class: "filter-item"
                            }, [
                              _createTextVNode(_toDisplayString(tag.title) + " ", 1),
                              _createVNode(_component_v_icon, {
                                class: "clearChartFilter",
                                onClick: tag.removeFunction
                              }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createTextVNode("mdi-close-circle")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading", "disabled"]),
      _createVNode(_component_v_card, {
        class: "pt-0 transparent",
        loading: _ctx.tableLoading
      }, {
        default: _withCtx(() => [
          (!_ctx.tableLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!_ctx.tableLoading && _ctx.teammembers && _ctx.teammembers.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "teammember-section" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTeammembers, (teammember, teammemberIndex) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                class: "teammember-section-item pl-3 pr-3 mt-3",
                                cols: "12",
                                md: "3",
                                sm: "6",
                                xs: "12",
                                key: `tmIndex_${teammemberIndex}_${_ctx.teammemberViewVersion}`
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { md: "3" }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_component_ProfileAvatar, {
                                              key: `tmAvatar_${teammemberIndex}_${_ctx.teammemberViewVersion}`,
                                              users: [teammember],
                                              justify: 'left',
                                              class: "profile-avatar",
                                              onSelectUser: _ctx.selectUser
                                            }, null, 8, ["users", "onSelectUser"]))
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { md: "9" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", null, [
                                              _createElementVNode("strong", null, _toDisplayString(teammember.fullName), 1)
                                            ]),
                                            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                                            _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                              _createTextVNode(_toDisplayString(_ctx.calculateBillableForTeammember(teammember.id)) + " Hour(s)", 1)
                                            ])), [
                                              [_directive_tooltip, 'Timesheet Total']
                                            ]),
                                            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                                            _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                              _createTextVNode(_toDisplayString(_ctx.getTeammemberCommitsTotal(teammember.id)) + " Line(s)", 1)
                                            ])), [
                                              [_directive_tooltip, 'Total of Commits']
                                            ]),
                                            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                            (_ctx.getVmUsage(teammember.id))
                                              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                                                  _createTextVNode(_toDisplayString(_ctx.getVmUsage(teammember.id)) + " Hour(s)", 1)
                                                ])), [
                                                  [_directive_tooltip, 'Virtual Desktop Usage Total']
                                                ])
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              class: "ml1 ma-0 pa-0",
                              md: "3",
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_5, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { md: "10" }, {
                                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                                              _createElementVNode("h3", { class: "ml-3" }, "Projects", -1)
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            md: "2",
                                            class: "text-center"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { md: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Pie, {
                                            data: _ctx.projectChartData,
                                            options: _ctx.getChartOptions(`project`)
                                          }, null, 8, ["data", "options"])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              class: "ml1 ma-0 pa-0",
                              md: "3",
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_6, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { md: "10" }, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                              _createElementVNode("h3", { class: "ml-3" }, "Epics", -1)
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            md: "2",
                                            class: "text-center"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { md: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Pie, {
                                            data: _ctx.epicChartData,
                                            options: _ctx.getChartOptions(`epic`)
                                          }, null, 8, ["data", "options"])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              class: "ml1 ma-0 pa-0",
                              md: "3",
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { md: "10" }, {
                                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                              _createElementVNode("h3", { class: "ml-3" }, "Activities", -1)
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            md: "2",
                                            class: "text-center"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { md: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Pie, {
                                            data: _ctx.acChartData,
                                            options: _ctx.getChartOptions(`activity`)
                                          }, null, 8, ["data", "options"])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              class: "ml1 ma-0 pa-0 pr-2",
                              md: "3",
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_8, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { md: "10" }, {
                                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                              _createElementVNode("h3", { class: "ml-3" }, "Technologies", -1)
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            md: "2",
                                            class: "text-center"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { md: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Pie, {
                                            data: _ctx.techChartData,
                                            options: _ctx.getChartOptions(`technology`)
                                          }, null, 8, ["data", "options"])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _cache[31] || (_cache[31] = _createElementVNode("div", { style: {"clear":"both"} }, null, -1)),
                        (_ctx.clickedTeamMember)
                          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  md: "12",
                                  sm: "12",
                                  cols: "12",
                                  class: "pl-6 pr-6 mt-5"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          class: "box-header",
                                          md: "12",
                                          sm: "12",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  md: "4",
                                                  sm: "4",
                                                  cols: "12",
                                                  class: "text-center"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("strong", null, [
                                                      _cache[10] || (_cache[10] = _createTextVNode(" GRAND TOTAL HOURS: ")),
                                                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.removeDecimals(this.grandTotalHour)), 1)
                                                    ])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  md: "4",
                                                  sm: "4",
                                                  cols: "12",
                                                  class: "text-center"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("strong", null, [
                                                      _cache[11] || (_cache[11] = _createTextVNode(" GRAND TOTAL ADJUSTMENT: ")),
                                                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.removeDecimals(this.grandTotalAdjustment)), 1)
                                                    ])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  md: "4",
                                                  sm: "4",
                                                  cols: "12",
                                                  class: "text-center"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("strong", null, [
                                                      _cache[12] || (_cache[12] = _createTextVNode(" GRAND TOTAL BILLABLE: ")),
                                                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.removeDecimals(this.grandTotalBillable)), 1)
                                                    ])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  md: "12",
                                  sm: "12",
                                  cols: "12",
                                  class: "pl-6 pr-6 mt-5"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          class: "box-header",
                                          md: "12",
                                          sm: "12",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                                            _createElementVNode("strong", null, "Warnings", -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          md: "12",
                                          sm: "12",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTeammemberTimesheetIssues(
                      _ctx.clickedTeamMember.id
                    ), (item, index) => {
                                              return (_openBlock(), _createElementBlock("div", {
                                                key: `git-err-${index}`
                                              }, [
                                                _createVNode(_component_v_alert, {
                                                  border: "right",
                                                  "colored-border": "",
                                                  type: "warning",
                                                  elevation: "2"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1))
                                              ]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  md: "12",
                                  sm: "12",
                                  cols: "12",
                                  class: "pl-6 pr-6 mt-5"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          class: "box-header",
                                          md: "12",
                                          sm: "12",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                                            _createElementVNode("strong", null, "Weekly Performance Report", -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          md: "12",
                                          sm: "12",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_component_weekly_performance_report, {
                                              key: `wpr_${_ctx.weeklyPerformanceReportVersion}`,
                                              teammember: _ctx.clickedTeamMember,
                                              timesheets: 
                      _ctx.getTeammemberFilteredTimesheets(_ctx.clickedTeamMember.id)
                    
                                            }, null, 8, ["teammember", "timesheets"]))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  md: "12",
                                  sm: "12",
                                  xs: "12"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teammemberReport, (listItem, index) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        class: "mt-2",
                                        md: "12",
                                        sm: "12",
                                        cols: "12",
                                        key: 'row_' + index
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listItem.weeklyReport, (weekItems, weekIndex) => {
                                            return (_openBlock(), _createBlock(_component_v_row, {
                                              class: "mb-10 timesheetreport-body",
                                              key: 'week_' + index + weekIndex
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "header mb-1 timesheetreport-item",
                                                  md: "12",
                                                  sm: "12",
                                                  cols: "12"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, {
                                                          md: "9",
                                                          sm: "9",
                                                          cols: "9"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("h3", null, "Timesheet Report Week " + _toDisplayString(weekItems.week), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_col, {
                                                          md: "3",
                                                          sm: "3",
                                                          cols: "3",
                                                          class: "text-right"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("h3", null, _toDisplayString(listItem.teammemberName), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, {
                                                  md: "12",
                                                  sm: "12",
                                                  cols: "12",
                                                  class: "timesheetreport-item"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, null, {
                                                      default: _withCtx(() => [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekItems.timesheets, (timesheetItem, timesheetIndex) => {
                                                          return (_openBlock(), _createBlock(_component_v_col, {
                                                            class: "rowItem",
                                                            md: "12",
                                                            sm: "12",
                                                            cols: "12",
                                                            key: 'timesheet_' + index + weekIndex + timesheetIndex
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (
                            timesheetIndex === 0 ||
                            _ctx.formatDate(
                              weekItems.timesheets[timesheetIndex].Date
                            ) !=
                              _ctx.formatDate(
                                weekItems.timesheets[timesheetIndex - 1].Date
                              )
                          )
                                                                ? (_openBlock(), _createBlock(_component_v_row, {
                                                                    key: 0,
                                                                    style: _normalizeStyle([
                            timesheetIndex === 0
                              ? { 'margin-top': '-10px' }
                              : { 'margin-top': '-15px' },
                          ])
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_v_col, {
                                                                        md: "2",
                                                                        sm: "2",
                                                                        cols: "2",
                                                                        class: "text-center titles"
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createElementVNode("span", _hoisted_12, [
                                                                            _createElementVNode("strong", null, _toDisplayString(timesheetItem.Date.toDateString()), 1)
                                                                          ])
                                                                        ]),
                                                                        _: 2
                                                                      }, 1024)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["style"]))
                                                                : _createCommentVNode("", true),
                                                              _createVNode(_component_v_row, { class: "timesheet-item" }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_col, {
                                                                    md: "2",
                                                                    sm: "2",
                                                                    cols: "2",
                                                                    class: "text-center datas text-flex-container"
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createElementVNode("span", _hoisted_13, [
                                                                        _createElementVNode("span", _hoisted_14, _toDisplayString(timesheetItem.IssueNo), 1),
                                                                        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                                                                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.removeDecimals(timesheetItem.Effort)) + "H", 1),
                                                                        _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                                                                        (timesheetItem.Adjustment)
                                                                          ? (_openBlock(), _createElementBlock("span", {
                                                                              key: 0,
                                                                              class: "text-subtitle-1",
                                                                              style: _normalizeStyle([
                                  timesheetItem.Adjustment > 0
                                    ? { color: '#4caf50' }
                                    : { color: 'red' },
                                ])
                                                                            }, [
                                                                              _cache[16] || (_cache[16] = _createTextVNode("(")),
                                                                              (timesheetItem.Adjustment > 0)
                                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, "+"))
                                                                                : _createCommentVNode("", true),
                                                                              _createTextVNode(_toDisplayString(_ctx.removeDecimals(timesheetItem.Adjustment)) + "H)", 1)
                                                                            ], 4))
                                                                          : _createCommentVNode("", true)
                                                                      ])
                                                                    ]),
                                                                    _: 2
                                                                  }, 1024),
                                                                  _createVNode(_component_v_col, {
                                                                    md: "4",
                                                                    sm: "4",
                                                                    cols: "4",
                                                                    class: "text-left datas"
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createElementVNode("span", null, [
                                                                        _createElementVNode("strong", null, [
                                                                          _createVNode(_component_v_icon, { title: "Project" }, {
                                                                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                                              _createTextVNode("mdi-briefcase ")
                                                                            ])),
                                                                            _: 1
                                                                          }),
                                                                          _cache[20] || (_cache[20] = _createTextVNode(":"))
                                                                        ]),
                                                                        _createTextVNode(" " + _toDisplayString(timesheetItem.Project.Name), 1)
                                                                      ]),
                                                                      _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                                                                      _createElementVNode("span", null, [
                                                                        _createElementVNode("strong", null, [
                                                                          _createVNode(_component_v_icon, { title: "Epic" }, {
                                                                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                                              _createTextVNode("mdi-cone")
                                                                            ])),
                                                                            _: 1
                                                                          }),
                                                                          _cache[22] || (_cache[22] = _createTextVNode(":"))
                                                                        ]),
                                                                        _createTextVNode(" " + _toDisplayString(timesheetItem.Epic), 1)
                                                                      ]),
                                                                      _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                                                                      _createElementVNode("span", null, [
                                                                        _createElementVNode("strong", null, [
                                                                          _createVNode(_component_v_icon, { title: "Activity" }, {
                                                                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                                              _createTextVNode("mdi-cog-outline")
                                                                            ])),
                                                                            _: 1
                                                                          }),
                                                                          _cache[24] || (_cache[24] = _createTextVNode(":"))
                                                                        ]),
                                                                        _createTextVNode(" " + _toDisplayString(timesheetItem.Activity), 1)
                                                                      ]),
                                                                      _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                                                                      _createElementVNode("span", null, [
                                                                        _createElementVNode("strong", null, [
                                                                          _createVNode(_component_v_icon, { title: "Technology" }, {
                                                                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                                              _createTextVNode("mdi-lan")
                                                                            ])),
                                                                            _: 1
                                                                          }),
                                                                          _cache[26] || (_cache[26] = _createTextVNode(":"))
                                                                        ]),
                                                                        _createTextVNode(" " + _toDisplayString(timesheetItem.Technology), 1)
                                                                      ]),
                                                                      _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1))
                                                                    ]),
                                                                    _: 2
                                                                  }, 1024),
                                                                  _createVNode(_component_v_col, {
                                                                    md: "6",
                                                                    sm: "6",
                                                                    cols: "6",
                                                                    class: "datas"
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createElementVNode("span", null, _toDisplayString(timesheetItem.Description), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1024)
                                                                ]),
                                                                _: 2
                                                              }, 1024)
                                                            ]),
                                                            _: 2
                                                          }, 1024))
                                                        }), 128))
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
              !_ctx.tableLoading &&
              (!_ctx.teammemberReport || _ctx.teammemberReport.length === 0)
            )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("h1", _hoisted_18, _toDisplayString(_ctx.message), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _createVNode(_component_SighteFormsScript)
  ], 64))
}