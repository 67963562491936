<template>
  <v-card-text>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        xs="12"
        v-for="(item, index) in items"
        :key="item.IssueNo"
      >
        <div class="rounded-lg dataBody pa-3">
          <div class="title-board">
            <v-row>
              <v-col md="3" class="text-left">
                {{ item.totalLines }} Line(s)
              </v-col>
              <v-col md="6" class="text-center">
                Task {{ item.IssueNo }}
              </v-col>
              <v-col md="3" class="text-right">
                {{ item.totalHours }} Hour(s)
              </v-col>
            </v-row>
          </div>
          <div class="descriptions">
            <v-row>
              <v-col
                md="12"
                :class="`${
                  desIndex === item.descriptions.length ? `` : `item`
                }`"
                v-for="(description, desIndex) in item.descriptions"
                :key="`d_${desIndex}`"
              >
                - {{ description }}
              </v-col>
            </v-row>
          </div>
          <div class="activities">
            <v-row>
              <v-col md="12">
                <div style="margin-bottom: 1px">
                  <span
                    :title="`${activity.name} ${activity.totalHours}H`"
                    v-for="(activity, actIndex) in item.activityItems"
                    :key="`a_${actIndex}`"
                    :style="`${prepareChartStyle(
                      actIndex,
                      item.totalHours,
                      activity.totalHours
                    )}`"
                    @click="setActivity(activity.id)"
                  >
                    {{ activity.name }} ({{ activity.totalHours }}H)
                  </span>
                </div>
                <div>
                  <span
                    :title="`${technology.name} ${technology.totalHours}H`"
                    v-for="(technology, tchIndex) in item.technologyItems"
                    :key="`t_${tchIndex}`"
                    :style="`${prepareChartStyle(
                      tchIndex,
                      item.totalHours,
                      technology.totalHours,
                      true
                    )}`"
                    @click="setTechnology(technology.id)"
                  >
                    {{ technology.name }} ({{ technology.totalHours }}H)
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="actions">
            <v-row>
              <v-col md="4" class="text-center">
                <v-btn variant="outlined" :disabled="true"> Code Quality</v-btn>
              </v-col>
              <v-col md="4" class="text-center">
                <v-btn variant="outlined" @click="OnReactClick(item)">
                  + Comment</v-btn
                >
              </v-col>
              <v-col md="4" class="text-center">
                <v-btn variant="outlined" :disabled="true" outlined>
                  SME Review</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col></v-row
    >

    <v-dialog
      persistent
      v-if="showReactionDialog"
      v-model="showReactionDialog"
      @keydown.esc="closeReactionDialog"
      max-width="440px"
    >
      <reaction
        @cancel="closeReactionDialog"
        @saved="closeReactionDialog"
        @errorRaised="errorRaised"
        @successRaised="successRaised"
        :timesheetId="selectedTimesheetId"
      ></reaction>
    </v-dialog>
  </v-card-text>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Timesheet from "shared-components/src/models/Timesheet";
import ProfileAvatar from "@/components/Profile/ProfileAvatar.vue";
import reaction from "./WeeklyPerformanceReportReaction.vue";
import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../store/types";
import { mapMutations } from "vuex";
import { UiTeammember } from "./ProjectLeadReport.vue";
interface WeeklyPerformanceReportDictionaryItem {
  name: string;
  id: string;
  totalHours: number;
}

interface WeeklyPerformanceReportItem {
  IssueNo: string;
  totalLines: number;
  totalHours: number;
  descriptions: string[];
  timeSheetIds: string[];
  activityItems: WeeklyPerformanceReportDictionaryItem[];
  technologyItems: WeeklyPerformanceReportDictionaryItem[];
}

export default defineComponent({
  components: {
    ProfileAvatar,
    reaction,
  },
  props: {
    teammember: {
      type: Object as () => UiTeammember,
      required: true,
    },
    timesheets: {
      type: Array as () => Timesheet[],
      required: true,
    },
  },
  data() {
    return {
      items: [] as WeeklyPerformanceReportItem[],
      showReactionDialog: false,
      selectedTimesheetId: "",
      weekDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      colorPalette: [
        "#9e0142",
        "#d53e4f",
        "#f46d43",
        "#fdae61",
        "#e6f598",
        "#abdda4",
        "#66c2a5",
        "#3288bd",
        "#5e4fa2",
      ],
    };
  },
  mounted() {
    this.initItems();
  },
  methods: {
    ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
    errorRaised(msg: string): void {
      this.SET_SNAKBAR_MODEL({
        body: msg,
        status: "error",
        button: "ok",
        show: true,
        handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
      });
    },
    successRaised(msg: string): void {
      this.SET_SNAKBAR_MODEL({
        body: msg,
        status: "success",
        button: "ok",
        show: true,
        handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
      });
    },
    closeReactionDialog(): void {
      this.showReactionDialog = false;
    },
    OnReactClick(item: WeeklyPerformanceReportItem) {
      this.showReactionDialog = true;
      this.selectedTimesheetId = item.timeSheetIds[0];
    },

    setActivity(id: string) {},
    setTechnology(id: string) {},
    initItems() {
      this.items = [];
      if (this.timesheets) {
        this.timesheets.forEach((timesheet) => {
          let currentItem = this.items.find(
            (x) => x.IssueNo == timesheet.IssueNo
          );

          if (currentItem == null) {
            // add item
            const newItem = {
              IssueNo: timesheet.IssueNo,
              descriptions: [],
              activityItems: [],
              technologyItems: [],
              timeSheetIds: [],
              totalHours: 0,
              totalLines: 0,
            } as WeeklyPerformanceReportItem;
            this.items.push(newItem);

            currentItem = newItem;
          }
          const totalHour = timesheet.Effort + timesheet.Adjustment;
          const weekDay = this.weekDays[timesheet.Date.getDay()];
          const descriptionItem = `${weekDay} (${totalHour}H) ${timesheet.Description}`;
          currentItem.descriptions.push(descriptionItem);
          currentItem.timeSheetIds.push(timesheet.id);
          currentItem.totalHours += totalHour;

          let totalLines = 0;
          const timesheetCommits = (timesheet as any).Commits as
            | any[]
            | undefined;
          if (timesheetCommits) {
            timesheetCommits.forEach((c) => {
              totalLines += c.linesChanged;
            });
          }
          currentItem.totalLines = totalLines;

          // set activity items
          const currentActivity = currentItem.activityItems.find(
            (x) => x.id == timesheet.ActivityId
          );
          if (currentActivity == null) {
            const newActivity = {
              id: timesheet.ActivityId,
              name: timesheet.Activity,
              totalHours: totalHour,
            } as WeeklyPerformanceReportDictionaryItem;
            currentItem.activityItems.push(newActivity);
          } else {
            currentActivity.totalHours += totalHour;
          }

          // set technology items
          const currentTechnology = currentItem.technologyItems.find(
            (x) => x.id == timesheet.TechnologyId
          );
          if (currentTechnology == null) {
            const newTechnology = {
              id: timesheet.TechnologyId,
              name: timesheet.Technology,
              totalHours: totalHour,
            } as WeeklyPerformanceReportDictionaryItem;
            currentItem.technologyItems.push(newTechnology);
          } else {
            currentTechnology.totalHours += totalHour;
          }
        });
      }
    },
    prepareChartStyle(
      index: number,
      totalHour: number,
      hour: number,
      reverseColor: boolean = false
    ) {
      const percentage = (hour * 100) / totalHour;
      const color =
        this.colorPalette[
          reverseColor ? index % 9 : this.colorPalette.length - 1 - (index % 9)
        ];
      return `width:${percentage}%;
       background-color:${color};
       display: inline-block;
       height: 55px;
       vertical-align: middle;
       line-height: 55px;
       padding-left: 10px;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;`;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.dataBody {
  background-color: $dark_gray;
  height: 100%;
  min-height: 400px;
}

.title-board {
  border-bottom: 1px solid;
  padding-bottom: 7px;
  margin-bottom: 55px;
}
.descriptions {
  height: 130px;
  overflow-y: auto;
  overflow-x: clip;
  margin-bottom: 40px;
}

.descriptions .item {
  border-bottom: 1px dashed;
}
.activities {
  height: 136px;
  margin-bottom: 36px;
}
.actions {
  min-height: 70px;
  margin-top: 15px;
}
</style>
