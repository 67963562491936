import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "text_white"
}
const _hoisted_3 = { class: "text_white" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "menu-action-btns" }
const _hoisted_11 = { class: "menu-action-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_header = _resolveComponent("v-card-header")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showPositionMenuDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPositionMenuDialog) = $event)),
    persistent: "",
    "max-width": "1000px",
    loading: _ctx.dialogLoading,
    disabled: _ctx.dialogLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "pa-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  (_ctx.getTeammember)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        class: "text-center"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.getTeammember.id)
                            ? (_openBlock(), _createBlock(_component_v_avatar, {
                                key: 0,
                                color: !_ctx.getTeammember.photoUrl ? _ctx.GetColour(_ctx.getTeammember.fullName) : null
                              }, {
                                default: _withCtx(() => [
                                  _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                  (_ctx.getTeammember.photoUrl)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: "pa-1 profile-photo",
                                        src: _ctx.getTeammember.photoUrl,
                                        alt: _ctx.getTeammember.fullName
                                      }, null, 8, _hoisted_1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.GetInitials(_ctx.getTeammember.fullName)), 1))
                                ]),
                                _: 3
                              }, 8, ["color"]))
                            : (_openBlock(), _createBlock(_component_v_avatar, {
                                key: 1,
                                color: _ctx.GetColour(_ctx.getTeammember.fullName)
                              }, {
                                default: _withCtx(() => [
                                  _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getTeammember.fullName), 1)
                                ]),
                                _: 3
                              }, 8, ["color"])),
                          _createTextVNode(" " + _toDisplayString(_ctx.getTeammember.fullName), 1)
                        ]),
                        _: 3
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_icon, {
                    class: "close-btn",
                    onClick: _ctx.closeDialog
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 3
              })
            ]),
            _: 3
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: "tonal",
                        class: "b-r-5 info-boxs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "pa-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        class: "text-center"
                                      }, {
                                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                                          _createTextVNode(" Project Info ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "12" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Project: " + _toDisplayString(_ctx.selectedProject?.Name), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "12" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Epics: " + _toDisplayString(_ctx.getEpics), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: "tonal",
                        class: "b-r-5 info-boxs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "pa-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        class: "text-center"
                                      }, {
                                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                                          _createTextVNode(" Addons Info ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[5] || (_cache[5] = _createTextVNode(" Timesheet Processing: ")),
                                          (_ctx.selectedCommitment?.TimesheetProcessing)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[7] || (_cache[7] = _createTextVNode(" OfficeSpace: ")),
                                          (_ctx.selectedCommitment?.OfficeSpaceId)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                                          _createTextVNode(" Training Roadmaps: - ")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[10] || (_cache[10] = _createTextVNode(" Contract: ")),
                                          (_ctx.selectedCommitment?.ContactVerification || _ctx.selectedCommitment?.ContractManagement || _ctx.selectedCommitment?.IDVerification)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[12] || (_cache[12] = _createTextVNode(" Virtual Desktop: ")),
                                          (_ctx.selectedCommitment?.VirtualDesktopSpec)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_7, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[14] || (_cache[14] = _createTextVNode(" Payroll: ")),
                                          (_ctx.selectedCommitment?.SalaryCurrency)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_8, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _cache[16] || (_cache[16] = _createTextVNode(" Line Manager: ")),
                                          (_ctx.selectedCommitment?.LineManagerId)
                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                  _createTextVNode("mdi-check")
                                                ])),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                                          _createTextVNode(" TDM: - ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: "tonal",
                        class: "b-r-5 action-boxs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "pa-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        class: "text-center"
                                      }, {
                                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                                          _createTextVNode(" Actions ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createTextVNode("Extend Contract")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                                        _createTextVNode("Terminate Contract")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                                        _createTextVNode("Terminate Position")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                                        _createTextVNode("Plan Replacement")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                        _createTextVNode("Rate Change")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                        _createTextVNode("Access Update")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createTextVNode("Request 1-On-1")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                        _createTextVNode("Bonus")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                                        _createTextVNode("Feedback/Warning")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                                        _createTextVNode("Change Manager")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[29] || (_cache[29] = [
                                        _createTextVNode("Virtual Desktop")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                                        _createTextVNode("Change Work Hours")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, {
                                      class: "menu-btn",
                                      "prepend-icon": ""
                                    }, {
                                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                                        _createTextVNode("Office")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: "tonal",
                        class: "b-r-5 action-boxs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "pa-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        class: "text-center"
                                      }, {
                                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                                          _createTextVNode(" Insights ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                                        _createTextVNode("Current Status")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                                        _createTextVNode("Timesheet Flag Yesterday")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[35] || (_cache[35] = [
                                        _createTextVNode("Timesheet Flag Last Week")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[36] || (_cache[36] = [
                                        _createTextVNode("Contract Ending Soon")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[37] || (_cache[37] = [
                                        _createTextVNode("Add-On Upsell")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[38] || (_cache[38] = [
                                        _createTextVNode("Notification")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createTextVNode("Attention")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                      default: _withCtx(() => _cache[40] || (_cache[40] = [
                                        _createTextVNode("Issues")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue", "loading", "disabled"]))
}