<template>
  <div>
    <v-form ref="mainForm" :loading="loading" :disabled="loading">
      <v-card flat>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title :class="{ active: model.timeSheetProcessing.Enabled }">
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Timesheet Processing
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <TimeSheetProcessingAddon :timeSheetProcessing="model.timeSheetProcessing"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                :class="{ active: model.payRoll.Enabled }"
              >
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Payroll
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <PayrollAddon :payRoll="model.payRoll"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                :class="{ active: model.contract.Enabled }"
              >
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Contract
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <ContractAddon :contract="model.contract"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                v-slot="{ expanded }"
                :class="{ active: model.virtualDesktop.Enabled }"
              >
                <v-row no-gutters>
                  <v-col cols="4"> Virtual Desktop </v-col>
                </v-row>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <VirtualDesktopAddon 
                  :showEnableCheckBox="true"
                  :virtualDesktopSpec="model.virtualDesktop" 
                  :diskValues="diskValues" 
                  :cpuValues="cpuValues" 
                  :ramValues="ramValues"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              :disabled="
                commitmentModel.LocationType == 'Remote' ||
                commitmentModel.OfficeLocationId != 'OfficeSpace'
              "
            >
              <v-expansion-panel-title
                :class="{ active: model.officeSpace.Enabled }"
              >
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Office Space
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <OfficeSpaceAddon :calculateOfficeSpace="calculateOfficeSpace" :officeSpace="model.officeSpace" :officeSpaces="officeSpaces"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                :class="{ active: model.lineManager.Enabled }"
              >
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Line Manager
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <LineManagerAddon :lineManagerAddon="model.lineManager" :lineManagers="lineManagers"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Traning Roadmaps
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">
                      Talent Development Manager
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="secondary_btn" @click="backStep" :loading="loading"
        >Previous</v-btn
      >
      <v-btn class="primary_btn_v2" @click="nextStep" :loading="loading"
        >Save</v-btn
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import store from "@/store";
import PayrollAddon from "shared-components/src/components/Commitment/Addons/PayrollAddon.vue"
import ContractAddon from "shared-components/src/components/Commitment/Addons/ContractAddon.vue"
import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
import LineManagerAddon from "shared-components/src/components/Commitment/Addons/LineManagerAddon.vue"
import OfficeSpaceAddon from "shared-components/src/components/Commitment/Addons/OfficeSpaceAddon.vue";
import TimeSheetProcessingAddon from "shared-components/src/components/Commitment/Addons/TimeSheetProcessingAddon.vue";

export default defineComponent({
  components: {
    PayrollAddon,
    ContractAddon,
    VirtualDesktopAddon,
    LineManagerAddon,
    OfficeSpaceAddon,
    TimeSheetProcessingAddon
  },
  props: ["loading", "lineManagers", "officeSpaces", "commitmentModel"],
  data() {
    return {
      locations: [
        "Melbourne - Australia",
        "Sydney - Australia",
        "Manila - Philippines",
        "Kuala Lumpur - Malaysia",
        "Tehran - Iran",
      ],
      policies: ["Windows 10 Enterprise CIS"],
      levels: ["L1", "L2"],
      salaries: ["Hourly", "Monthly"],
      currencies: ["AUD", "USD", "EUR"],
      operatingSystems: [
        "Windows 10",
        "Windows 11",
        "Ubuntu 22",
        "Ubuntu 24",
        "Debian 9",
        "Debian 10",
        "CentOS 8",
      ],
      ramValues: {
        0: '8',
        1: '16',
        2: '24',
        3: '32',
      },
      cpuValues: {
        0: '2',
        1: '4',
        2: '6',
      },
      diskValues: {
        0: '128',
        1: '256',
        2: '512',
      },
      model: {
        virtualDesktop: {
          AdditionalSecurityBL: false,
          AdditionalSecurityNG: false,
          Cpu: 0,
          Disk: 0,
          Enabled: false,
          Level: null as string | null,
          Location: null as string | null,
          OperatingSystem: null as string | null,
          Policy: null as string | null,
          Ram: 0,
        },
        lineManager: {
          Enabled: false,
          LineManagerId: null as string | null,
        },
        payRoll: {
          Enabled: false,
          Amount: 0,
          Currency: null as string | null,
          Salary: null as string | null,
        },
        contract: {
          Enabled: false,
          ContractManagement: false,
          ContactVerification: false,
          IDVerification: false,
        },
        officeSpace: {
          Enabled: false,
          officeSpaceId: null as string | null,
        },
        timeSheetProcessing: {
          Enabled: false,
        },
      },
    };
  },
  mounted() {
    if(this.commitmentModel.LocationType == "Remote" || this.commitmentModel.OfficeLocationId != "OfficeSpace")
    {
      this.model.officeSpace.Enabled = false;
    }
    else{
      this.model.officeSpace.Enabled = true;
    }
  },
  methods: {
    backStep() {
      this.$emit("BackStep");
    },
    async nextStep() {
      const isValid = await (this.$refs.mainForm as any).validate();
      if (isValid.valid) {
        const model = JSON.parse(JSON.stringify(this.model))
        if(model.virtualDesktop.Enabled){
          model.virtualDesktop.Cpu = Number(Object.values(this.cpuValues)[this.model.virtualDesktop.Cpu])
          model.virtualDesktop.Ram = Number(Object.values(this.ramValues)[this.model.virtualDesktop.Ram])
          model.virtualDesktop.Disk = Number(Object.values(this.diskValues)[this.model.virtualDesktop.Disk])
        }
        this.$emit("NextStep", model);
      }
    },
  },
  watch: {
    commitmentModel: {
      handler(newVal) {
        if(newVal.LocationType == "Remote" || newVal.OfficeLocationId != "OfficeSpace")
        {
          if(this.model.officeSpace.Enabled == true){
            store.dispatch("showWarningMessage", "Office space will be disabled because it is not supported by your location type")
          }
          this.model.officeSpace.Enabled = false;
          this.model.officeSpace.officeSpaceId = null;
        }
        else{
          this.model.officeSpace.Enabled = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    calculateOfficeSpace() {
      let message = "You Selected";
      if (this.commitmentModel.LocationType == "OnSite") {
        if (
          this.commitmentModel.StartWorkClock == "Flexible" ||
          this.commitmentModel.EndWorkClock == "Flexible" ||
          (this.commitmentModel.WokingDays &&
            this.commitmentModel.WokingDays.includes("Flexible"))
        ) {
          return false;
        }
        const startTime = moment(this.commitmentModel.StartWorkClock, "h:mm A");
        const endTime = moment(this.commitmentModel.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${
          this.commitmentModel.WokingDays.length
        } days per week (${this.commitmentModel.WokingDays.join(",")}) and 
          ${duration.asHours()} Hours per day`;
        return message;
      } else if (this.commitmentModel.LocationType == "Hybrid") {
        if (
          this.commitmentModel.StartWorkClock == "Flexible" ||
          this.commitmentModel.EndWorkClock == "Flexible" ||
          this.commitmentModel.OfficeDaysPerWeek == "Flexible"
        ) {
          return false;
        }
        const startTime = moment(this.commitmentModel.StartWorkClock, "h:mm A");
        const endTime = moment(this.commitmentModel.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${
          this.commitmentModel.OfficeDaysPerWeek
        } days per week in these days (${this.commitmentModel.DaysOfWeekInOffice.join(
          ","
        )}) and ${duration.asHours()} Hours per day`;
        return message;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";
.actionBtns {
  display: flex;
  justify-content: space-between;
}
.active {
  background-color: $c_emerald;
}
</style>
