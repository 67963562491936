import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "10" }, {
        default: _withCtx(() => [
          _createVNode(_component_TextField, {
            modelValue: _ctx.searchName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchName) = $event)),
            onKeyup: _withKeys(_ctx.searchHandler, ["enter"]),
            placeholder: "Search For...",
            "append-inner-icon": "mdi-magnify",
            "onClick:appendInner": _ctx.searchHandler,
            "hide-details": ""
          }, null, 8, ["modelValue", "onKeyup", "onClick:appendInner"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { cols: "2" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.toggleNewRoadMap,
            class: "roadmap-button",
            "prepend-icon": "mdi-plus",
            stacked: ""
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("New Training Roadmap")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}