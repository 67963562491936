import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-page-container" }
const _hoisted_2 = {
  key: 1,
  class: "main-search-container"
}
const _hoisted_3 = { class: "result-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_SelectedChips = _resolveComponent("SelectedChips")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_EmployeeItem = _resolveComponent("EmployeeItem")!
  const _component_Filter = _resolveComponent("Filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDetailsLoading)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          width: 3,
          size: 70,
          color: "red",
          indeterminate: "",
          class: "ma-auto"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_SelectedChips, {
              details: _ctx.details,
              skills: _ctx.skills,
              criteria: _ctx.criteria,
              onCriteriaChanged: _ctx.criteriaChanged
            }, null, 8, ["details", "skills", "criteria", "onCriteriaChanged"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_row, { class: "h-100" }, {
              default: _withCtx(() => [
                (_ctx.isResultLoading)
                  ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                      key: 0,
                      width: 3,
                      size: 70,
                      color: "red",
                      indeterminate: "",
                      class: "ma-auto"
                    }))
                  : (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      xs: "12",
                      md: "8"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_v_row, { class: "title-row" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "12",
                                md: "8",
                                class: "align-self-center"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, "Results - " + _toDisplayString(_ctx.employees.length) + " cases", 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "12",
                                md: "4"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_SelectField, {
                                      items: _ctx.sortByItems,
                                      "item-title": "text",
                                      "item-value": "value",
                                      label: "Sort By",
                                      modelValue: _ctx.criteria.sortBy,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criteria.sortBy) = $event)),
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    }, null, 8, ["items", "modelValue"])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employees, (item, index) => {
                                return (_openBlock(), _createBlock(_component_v_col, {
                                  cols: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "4",
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_EmployeeItem, {
                                      employee: item,
                                      skills: _ctx.skills,
                                      details: _ctx.details,
                                      showInScene: _ctx.showInScene,
                                      onViewTmDetails: _ctx.ViewTmDetails
                                    }, null, 8, ["employee", "skills", "details", "showInScene", "onViewTmDetails"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })),
                _createVNode(_component_v_col, {
                  xs: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Filter, {
                      details: _ctx.details,
                      skills: _ctx.skills,
                      criteria: _ctx.criteria,
                      positionJD: _ctx.positionJD,
                      onDoSearch: _ctx.doSearch
                    }, null, 8, ["details", "skills", "criteria", "positionJD", "onDoSearch"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}