import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, { class: "align-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("h2", null, "Talents", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "6",
          class: "text-right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_menu, {
              "offset-y": "",
              transition: "scale-transition"
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({ class: "secondary_btn" }, props), {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" + Add Talent ")
                  ])),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, { link: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, { onClick: _ctx.showTalentsPopup }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { left: "" }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            }),
                            _cache[5] || (_cache[5] = _createTextVNode(" Select From List "))
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "mt-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.talents,
              headers: _ctx.tableHeaders,
              "item-value": "Id",
              "items-per-page": 10
            }, {
              "item.actions": _withCtx(({ item }) => [
                _createVNode(_component_v_icon, {
                  size: "small",
                  onClick: ($event: any) => (_ctx.deletTalent(item))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" mdi-delete ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["items", "headers"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      "max-width": "600px",
      modelValue: _ctx.talentsPopupVisiblity,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.talentsPopupVisiblity) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          label: "Select",
                          items: _ctx.teammemberList,
                          "item-title": "FullName",
                          "item-value": "Id",
                          modelValue: _ctx.teamMembersSelected,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.teamMembersSelected) = $event)),
                          multiple: "",
                          chips: "",
                          "return-object": ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "action-btns"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.cancelSelection,
                          class: "secondary_btn"
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.saveAndClose,
                          class: "primary_btn_v2"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Save")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}