import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-title-left" }
const _hoisted_5 = { class: "card-title-right" }
const _hoisted_6 = { class: "tm-rows" }
const _hoisted_7 = {
  key: 0,
  class: "no-tm"
}
const _hoisted_8 = { class: "tm-name" }
const _hoisted_9 = {
  key: 0,
  class: "tm-expired-description"
}
const _hoisted_10 = {
  key: 0,
  class: "chart-container"
}
const _hoisted_11 = {
  key: 0,
  class: "chart-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Line = _resolveComponent("Line")!
  const _component_Bar = _resolveComponent("Bar")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_ProjectDetail = _resolveComponent("ProjectDetail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_EpicList = _resolveComponent("EpicList")!
  const _component_ActivityWatchReport = _resolveComponent("ActivityWatchReport")!
  const _component_WazuhDashboard = _resolveComponent("WazuhDashboard")!
  const _component_VdSupport = _resolveComponent("VdSupport")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "card-title-left" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "primary_btn",
          "prepend-icon": "mdi-plus",
          onClick: _ctx.showNewProject
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("New Project")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_v_row, { class: "mt-3" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "12",
            key: project.Id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "project-item" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(project.Name), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_v_menu, null, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_btn, _mergeProps({ ref_for: true }, props, {
                                variant: "text",
                                size: "small",
                                class: "button",
                                icon: "mdi-dots-vertical"
                              }), null, 16)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    title: "Update Project",
                                    onClick: ($event: any) => (_ctx.showUpdateProject(project))
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    title: "Epic List",
                                    onClick: ($event: any) => (_ctx.showEpicPopup(project))
                                  }, null, 8, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_divider, { class: "mx-4" }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[8] || (_cache[8] = _createElementVNode("label", null, "Teammembers", -1)),
                        (project.Teammembers?.length == 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " There are no any Teammember for this Project"))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.Teammembers, (tm) => {
                          return (_openBlock(), _createBlock(_component_v_row, {
                            class: _normalizeClass(["tm-row", {'tm-expired': tm.IsCommitmentExpired}])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "8" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Avatar, {
                                    Name: tm.Name,
                                    Photo: tm.PhotoUrl,
                                    class: "tm-avatar"
                                  }, null, 8, ["Name", "Photo"]),
                                  _createElementVNode("span", _hoisted_8, _toDisplayString(tm.Name), 1),
                                  (tm.IsCommitmentExpired)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "The Commitment of this teammember is Expired"))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                cols: "2",
                                class: "pa-0 pt-2"
                              }, {
                                default: _withCtx(() => [
                                  (tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _createVNode(_component_Line, {
                                          data: _ctx.getLineChartData(tm),
                                          options: _ctx.lineOptions
                                        }, null, 8, ["data", "options"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                cols: "1",
                                class: "pa-0 pt-2"
                              }, {
                                default: _withCtx(() => [
                                  (tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _createVNode(_component_Bar, {
                                          data: _ctx.getBarChartData(tm),
                                          options: _ctx.barOptions
                                        }, null, 8, ["data", "options"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                cols: "1",
                                class: "text-right"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_menu, { "scroll-strategy": "close" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createVNode(_component_v_btn, _mergeProps({
                                        color: "black",
                                        variant: "text",
                                        size: "small",
                                        ref_for: true
                                      }, props, { icon: "mdi-dots-vertical" }), null, 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list, null, {
                                            default: _withCtx(() => [
                                              (tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0)
                                                ? (_openBlock(), _createBlock(_component_v_list_item, {
                                                    key: 0,
                                                    title: "Activity Watch Report",
                                                    onClick: ($event: any) => (_ctx.showActivityWatchPopup(tm))
                                                  }, null, 8, ["onClick"]))
                                                : _createCommentVNode("", true),
                                              (tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0)
                                                ? (_openBlock(), _createBlock(_component_v_list_item, {
                                                    key: 1,
                                                    title: "Wazuh Report",
                                                    onClick: ($event: any) => (_ctx.showWazuhPopup(tm))
                                                  }, null, 8, ["onClick"]))
                                                : _createCommentVNode("", true),
                                              (tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0)
                                                ? (_openBlock(), _createBlock(_component_v_list_item, {
                                                    key: 2,
                                                    title: "VD Support",
                                                    onClick: ($event: any) => (_ctx.showVdSupport(tm))
                                                  }, null, 8, ["onClick"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        }), 256))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showProjectModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showProjectModal) = $event)),
      "max-width": "700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProjectDetail, {
          onClose: _ctx.closeProjectPopup,
          onSavedProject: _ctx.savedProject,
          project: _ctx.selectedProject
        }, null, 8, ["onClose", "onSavedProject", "project"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showEpicList,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEpicList) = $event)),
      "max-width": "700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EpicList, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEpicList = false)),
          project: _ctx.selectedProject,
          isClient: true
        }, null, 8, ["project"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.showActivityWatchModal && _ctx.selectedVmId)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          modelValue: _ctx.showActivityWatchModal,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showActivityWatchModal) = $event)),
          "max-width": "700",
          persistent: "",
          onKeydown: _withKeys(_ctx.closeActivityWatchPopup, ["esc"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ActivityWatchReport, {
                      isClient: true,
                      vmId: _ctx.selectedVmId,
                      onClose: _ctx.closeActivityWatchPopup
                    }, null, 8, ["vmId", "onClose"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true),
    (_ctx.showWazuhModal && _ctx.selectedVmId)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 1,
          modelValue: _ctx.showWazuhModal,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showWazuhModal) = $event)),
          "max-width": "700",
          persistent: "",
          onKeydown: _withKeys(_ctx.closeWazuhPopup, ["esc"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_WazuhDashboard, { vmId: _ctx.selectedVmId }, null, 8, ["vmId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true),
    (_ctx.showVdSupportModal && _ctx.selectedVmId)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: _ctx.showVdSupportModal,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showVdSupportModal) = $event)),
          "max-width": "700",
          persistent: "",
          onKeydown: _withKeys(_ctx.closeVdSupportPopup, ["esc"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VdSupport, {
              isClient: true,
              onCancel: _ctx.closeVdSupportPopup,
              onCreateVdSupport: _ctx.createVdSupport
            }, null, 8, ["onCancel", "onCreateVdSupport"])
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true)
  ]))
}