import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "text_white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!

  return (_openBlock(), _createBlock(_component_v_avatar, {
    color: !_ctx.Photo ? _ctx.GetColour(_ctx.Name) : null
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.Photo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "pa-1 profile-photo",
            src: _ctx.Photo,
            alt: _ctx.Name
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.GetInitials(_ctx.Name)), 1))
    ]),
    _: 3
  }, 8, ["color"]))
}