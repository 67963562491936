<template>
  <div class="boxs-container">
    <div
      class="item-box"
      @click="changeMode('quickSearch')"
      :class="{ 'large-mode': mode == 'quickSearch' }"
    >
      <div v-if="mode != 'quickSearch'" class="first-page">
        Search By Skill
        <p class="description">Normal search</p>
      </div>

      <div v-if="mode == 'quickSearch'" class="second-page">
        <QuickSearch :details="details" :skills="skills"></QuickSearch>
      </div>
    </div>
    <div
      class="item-box"
      @click="changeMode('aisearch')"
      :class="{ 'large-mode': mode == 'aisearch' }"
    >
      <div v-if="mode != 'aisearch'" class="first-page">
        Search By JD
        <p class="description">AI search</p>
      </div>

      <div v-if="mode == 'aisearch'" class="second-page">
        <v-form
          ref="searchFromJD"
          v-model="valid"
          lazy-validation
          class="form-inputs jd-form-search"
        >
          <TextAreaField
            dense
            label="Type your Job Description"
            :rules="[rules.required]"
            v-model="jobDescription"
            outlined
          />
          <v-btn @click="SearchAi">Find it for me</v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CoreSkillService from "@/services/CoreSkillService";
import DetailsService from "@/services/DetailsService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import QuickSearch from "./QuickSearch.vue";
// import { OpenAIClient, AzureKeyCredential } from "@azure/openai"; //TODO: the package should be remove when the functionality implemented in Backend
import axios from "axios";

export default defineComponent({
  async mounted() {
    //TODO: this a sample of vectorizing the data, so it should be remove when the functionality implemented in Backend
    // const client = new OpenAIClient(
    //   "https://openai-for-test.openai.azure.com/",
    //   new AzureKeyCredential("392fc68cede74e99bdd02caf0b341d99")
    // );
    // console.log("this developer has an Bootstrap skill".split(""));
    // var embedded = await client.getEmbeddings("text-embedding-ada-002", [
    //   "i want to have a developer that has Bootstrap skill",
    // ]);
    // console.log(embedded.data[0].embedding.toString());
    await this.loadSkillsList();
    await this.loadDetailsList();
  },
  components: { QuickSearch },
  data() {
    return {
      valid: true,
      skills: [] as CoreSkill[],
      details: [] as Details[],
      mode: "normal",
      jobDescription: "",
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
      },
    };
  },
  methods: {
    async SearchAi() {
      //TODO: this a sample of using cognitive search, so it should be remove when the functionality implemented in Backend
      const isValid = await (this.$refs.searchFromJD as any).validate();
      if (isValid.valid) {
        const searchResults = await axios.get(
          `https://tm-profile-search-3.search.windows.net/indexes/cosmosdb-index/docs?api-version=2023-07-01-Preview&search=${this.jobDescription}`,
          {
            headers: {
              "api-key": "RvhzLLm65k17uExnsWupT4Nl43cIYpbtto9yqV9S2aAzSeDJR4kL",
              "Content-Type": "application/json",
            },
          }
        );
        console.log(searchResults);
      }
    },
    changeMode(mode: string) {
      this.mode = mode;
      this.$forceUpdate();
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
  },
});
</script>
<style scoped lang="scss">
@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;
  }
}
.boxs-container {
  height: 100%;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  transition: all 1s;
  .item-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 20px auto;
    width: 400px;
    height: 400px;
    background: white;
    border-radius: 35px;
    font-size: 20px;
    font-weight: lighter;
    letter-spacing: 2px;
    transition: 1s box-shadow;
    flex-direction: column;
    gap: 20px;
    transition: all 1s;
    padding: 25px;
    .first-page {
      text-align: center;
      .description {
        font-size: 14px;
      }
    }

    .second-page {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .jd-form-search {
        width: 100%;
        text-align: center;
      }
    }

    &:hover {
      box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
      &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fda8cf;
        border-radius: 35px;
        z-index: -1;
        animation: 1s clockwise infinite;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fda8cf;
        border-radius: 35px;
        z-index: -1;
        animation: 1s clockwise infinite;
      }

      &:after {
        background: #f3ce5e;
        animation: 2s counterclockwise infinite;
      }
    }
    &.large-mode {
      width: 800px;
      height: 800px;

      box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
      &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fda8cf;
        border-radius: 35px;
        z-index: -1;
        animation: 1s clockwise infinite;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fda8cf;
        border-radius: 35px;
        z-index: -1;
        animation: 1s clockwise infinite;
      }

      &:after {
        background: #f3ce5e;
        animation: 2s counterclockwise infinite;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .boxs-container {
    flex-direction: column;
    .item-box {
      width: 100%;
      max-width: 400px;
      &.large-mode {
        width: 100% !important;
        max-width: none;
      }
    }
  }
}
</style>
