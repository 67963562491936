<template>
  <v-avatar
    :color="!Photo ? GetColour(Name) : null"
  >
  <slot></slot>
    <img
      class="pa-1 profile-photo"
      v-if="Photo"
      :src="Photo"
      :alt="Name"
    />
    <span v-else class="text_white">
      {{ GetInitials(Name) }}
    </span>
  </v-avatar>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppHelper from "shared-components/src/utils/AppHelper";

export default defineComponent({
  props: ["Name", "Photo"],
  data() {
    return {};
  },
  methods: {
    GetColour(name: any) {
      return AppHelper.GetColour(name);
    },
    GetInitials(name: any) {
      return AppHelper.GetInitials(name);
    }
  }
});
</script>