<template>
  <div class="assessment-list">
    <v-overlay
      v-if="showAsPage"
      v-model="overlay"
      scroll-strategy="block"
      location-strategy="connected"
      opacity="0.8"
      persistent
      @click="clickOnOverlay"
    >
    </v-overlay>

    <AssessmentFilter
      @showOverlay="filterToggle"
      @backBtnClicked="handelBackToRoadMap"
      @doSearch="handleSearchFilter"
      @time-chip-closed="handelTimeChipClosed"
      :ShowBackBtn="backButtonVisibility"
      :showAssessmentBtn="assessmentBtnVisiblity"
      :top="topValue"
      :height="heightValue"
      :padding="paddingValue"
      :overlay="overlay"
      :showFilter="showFilter"
      :skills="skills"
    ></AssessmentFilter>

    <v-row class="card-list">
      <v-col cols="12" class="loading" v-if="isDetailsLoading">
        <v-progress-circular
          :width="3"
          :size="70"
          color="red"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col
        xs="12"
        sm="4"
        md="3"
        v-for="assessment in assessments"
        :key="assessment.Id"
      >
        <AssessmentListItem
          :assessment="assessment"
          :showAddToRoadMap="showAddToRoadMap"
          @AddAssessmentToRoadMap="addToRoadMap"
          :stepIds="stepIds"
        ></AssessmentListItem>
      </v-col>
      <v-col cols="12" v-if="showEmptyAssessmentCard">
        <empty-course-card></empty-course-card>
      </v-col>
    </v-row>

    <v-alert
      v-if="showErrorAlert"
      type="error"
      dismissible
      @input="showErrorAlert = false"
    >
      Error fetching data. Please try again.
    </v-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AssessmentListItem from "./AssessmentListItem.vue";
import AssessmentModel from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";
import AssessmentFilter from "./AssessmentFilter.vue";
import FindAssessmentsRequest from "shared-components/src/models/requests/FindAssessmentsRequest";
import AssessmentService from "@/services/AssessmentService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "@/services/CoreSkillService";

export default defineComponent({
  components: {
    AssessmentListItem,
    AssessmentFilter,
  },
  props: {
    showAsPage: {
      type: Boolean,
      default: false,
    },
    topValue: {
      type: String,
      default: "1px",
    },
    heightValue: {
      type: String,
      default: "610px",
    },
    paddingValue: {
      type: String,
      default: "40px",
    },
    backButtonVisibility: {
      type: Boolean,
      default: true,
    },
    showAddToRoadMap: {
      type: Boolean,
      default: false,
    },
    assessmentBtnVisiblity: {
      type: Boolean,
      default: true,
    },
    stepIds: {
      type: Array as () => String[],
      default: [],
    },
  },
  data() {
    return {
      showFilteredTime: false,
      overlay: false,
      showFilter: false,
      isDetailsLoading: false,
      showErrorAlert: false,
      searchQuery: {} as FindAssessmentsRequest,
      showEmptyAssessmentCard: false,
      showAssessmentFilter: false,
      assessments: [] as AssessmentModel[],
      selectedTime: null as any,
      skills: [] as CoreSkill[],
    };
  },
  computed: {
    assessmentData(): AssessmentModel[] {
      return this.assessmentData || [];
    },
  },
  async mounted() {
    try {
      this.isDetailsLoading = true;
      await this.loadSkillsList();
      this.assessments = await AssessmentService.GetList();
    } catch (error) {
      console.error("Error fetching data:", error);
      this.showErrorAlert = true;
    } finally {
      this.isDetailsLoading = false;
    }
  },
  methods: {
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    handleSearchFilter(filterValues: {
      duration: any;
      level: any;
      technology: any;
      text: any;
    }) {
      this.showFilteredTime = filterValues.duration !== null;
      this.searchQuery.Duration = filterValues.duration;
      this.searchQuery.Level = filterValues.level;
      this.searchQuery.Technology = filterValues.technology;
      this.searchQuery.Text = filterValues.text;
    },
    handelTimeChipClosed() {
      this.selectedTime = null;
    },
    goToAddAssessment() {
      this.$router.push({ name: "add-assessment" });
    },
    handleNewAssessment(newAssessment: any) {
      if (newAssessment) {
        this.assessmentData.unshift(newAssessment);
      }
    },
    clickOnOverlay() {
      this.showFilter = false;
      this.overlay = !this.overlay;
    },
    addToRoadMap(assessmentDetails: any) {
      this.$emit("AddAssessmentToRoadMap", assessmentDetails);
      this.handelBackToRoadMap();
    },
    handelBackToRoadMap() {
      this.$emit("CloseAssessmentsList");
    },
    filterToggle(filterVisible: any) {
      this.overlay = !this.overlay;
      this.showFilter = filterVisible;
      this.$emit("showOverlay", filterVisible);
    },
  },
  watch: {
    searchQuery: {
      handler(val) {
        this.isDetailsLoading = true;
        this.showEmptyAssessmentCard = false;
        this.assessments = [];
        AssessmentService.GetList(this.searchQuery).then((res: any) => {
          if (res.length <= 0) {
            this.showEmptyAssessmentCard = true;
          }
          this.assessments = res;
          this.isDetailsLoading = false;
        });
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.assessment-list {
  position: relative;

  .card-list {
    padding-top: 100px;
    display: flex;
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }
  }
}
</style>
