<template>
  <div>
    <v-row class="align-center">
      <v-col cols="6">
        <h2>Talents</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-menu offset-y transition="scale-transition">
          <template v-slot:activator="{ props }">
            <v-btn class="secondary_btn" v-bind="props"> + Add Talent </v-btn>
          </template>

          <v-list>
            <v-list-item link>
              <v-list-item-title @click="showTalentsPopup">
                <v-icon left>mdi-plus</v-icon>
                Select From List
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" class="mt-5">
        <v-data-table
          :items="talents"
          :headers="tableHeaders"
          item-value="Id"
          :items-per-page="10"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon size="small" @click="deletTalent(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog max-width="600px" v-model="talentsPopupVisiblity">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <AutoCompleteField
                label="Select"
                :items="teammemberList"
                item-title="FullName"
                item-value="Id"
                v-model="teamMembersSelected"
                multiple
                chips
                return-object
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="action-btns">
              <v-btn @click="cancelSelection" class="secondary_btn"
                >Cancel</v-btn
              >
              <v-btn @click="saveAndClose" class="primary_btn_v2">Save</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { RoadMapTalentModel } from "shared-components/src/models/RoadMapModel";
import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    talents: {
      type: Array as () => RoadMapTalentModel[],
    },
    teamMembers: {
      type: Array as () => TeammemberWithSummaryDetails[],
    },
  },
  data() {
    return {
      teamMembersSelected: [],
      talentsPopupVisiblity: false,
      tableHeaders: [
        { title: "Full Name", key: "FullName" },
        {
          title: "Actions",
          key: "actions",
          width: 100,
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    saveAndClose() {
      this.$emit("addTalentsToRoadMap", this.teamMembersSelected);
      this.teamMembersSelected = [];
      this.cancelSelection();
    },
    showTalentsPopup() {
      this.talentsPopupVisiblity = true;
    },
    cancelSelection() {
      this.talentsPopupVisiblity = false;
    },
    deletTalent(talent: any) {
      this.$emit("deleteTalentFromRoadMap", talent.Id);
    },
  },
  computed: {
    teammemberList() {
      const talentIds = this.talents?.map((item: any) => item.Id);
      return this.teamMembers
        ? this.teamMembers
            .map((item: any) => {
              return { Id: item.Id, FullName: item.FullName };
            })
            .filter((item: any) => !talentIds?.includes(item.Id))
        : [];
    },
  },
});
</script>

<style scoped>
.cancel-btn {
  margin-right: 8px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 15px;
}
</style>
