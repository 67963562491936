import Utils from "@/Helpers/Utils";
import ProjectLeadReport from "shared-components/src/models/ProjectLeadReport";
import ApiService from "./ApiService";
import Timesheet from "shared-components/src/models/Timesheet";
import RepositoryTeammemberCommit from "shared-components/src/viewModels/response/repository/RepositoryTeammemberCommit";
import RepositoryGitInsightRequest from "shared-components/src/viewModels/request/Repository/RepositoryGitInsightRequest";

export default class ProjectLeadReportService {
  public static async getList(
    startDate: Date,
    endDate: Date
  ): Promise<ProjectLeadReport> {
    return new Promise((resolve, reject) => {
      const sDate = Utils.addTimezoneOffsetToStartDate(startDate);
      const eDate = Utils.addTimezoneOffsetToEndDate(endDate);
      ApiService.post(`/reports/client/projectlead`, {
        startDate: sDate,
        endDate: eDate,
      })
        .then((result) => {
          if (!result.data) {
            resolve({} as ProjectLeadReport);
          } else {
            var timesheetReport = [] as Timesheet[];
            var projectleadReport = result.data as ProjectLeadReport;
            result.data.timesheetReport.forEach((doc: any) => {
              if (doc.Date) {
                doc.Date = Utils.vsDateToDatetime(doc.Date);
              }
              const item = { id: doc.id, ...doc } as Timesheet;
              timesheetReport.push(item);
            });
            projectleadReport.timesheetReport = timesheetReport;
            resolve(projectleadReport);
          }
        })
        .catch((commitmentError) => reject(commitmentError));
    });
  }

  public static async GetCommitListByProjectLead(
    model: RepositoryGitInsightRequest
  ): Promise<RepositoryTeammemberCommit[]> {
    model.StartDate =
      Utils.addTimezoneOffsetToStartDate(model.StartDate) || new Date();
    model.EndDate =
      Utils.addTimezoneOffsetToEndDate(model.EndDate) || new Date();
    return new Promise((resolve, reject) => {
      ApiService.post(`/repository/getCommitListByProjectLeadEmail`, model)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  }
}
