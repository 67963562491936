<template>
  <v-dialog v-model="showPositionMenuDialog" persistent max-width="1000px" :loading="dialogLoading" :disabled="dialogLoading">
    <v-card class="pa-3">
      <v-card-header>
        <v-row>
          <v-col cols="12" class="text-center" v-if="getTeammember">
            <v-avatar
              v-if="getTeammember.id"
              :color="!getTeammember.photoUrl ? GetColour(getTeammember.fullName) : null"
            >
            <slot></slot>
              <img
                class="pa-1 profile-photo"
                v-if="getTeammember.photoUrl"
                :src="getTeammember.photoUrl"
                :alt="getTeammember.fullName"
              />
              <span v-else class="text_white">
                {{ GetInitials(getTeammember.fullName) }}
              </span>
            </v-avatar>
            <v-avatar
              v-else
              :color="GetColour(getTeammember.fullName)"
            >
              <slot></slot>
              <span class="text_white">
                {{ getTeammember.fullName }}
              </span>
            </v-avatar>
            {{getTeammember.fullName}}
          </v-col>
          <v-icon class="close-btn" @click="closeDialog">mdi-close</v-icon>
        </v-row>
      </v-card-header>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 info-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Project Info
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      Project: {{selectedProject?.Name}}
                    </v-col>
                    <v-col cols="12">
                      Epics: {{getEpics}}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 info-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Addons Info
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      Timesheet Processing: 
                      <v-icon v-if="selectedCommitment?.TimesheetProcessing">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      OfficeSpace:
                      <v-icon v-if="selectedCommitment?.OfficeSpaceId">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Training Roadmaps: -
                    </v-col>
                    <v-col cols="6">
                      Contract:
                      <v-icon v-if="selectedCommitment?.ContactVerification || selectedCommitment?.ContractManagement || selectedCommitment?.IDVerification">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Virtual Desktop:
                      <v-icon v-if="selectedCommitment?.VirtualDesktopSpec">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Payroll:
                      <v-icon v-if="selectedCommitment?.SalaryCurrency">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Line Manager:
                      <v-icon v-if="selectedCommitment?.LineManagerId">mdi-check</v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      TDM: -
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 action-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Actions
                    </v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn" prepend-icon="">Extend Contract</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Terminate Contract</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Terminate Position</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Plan Replacement</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Rate Change</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Access Update</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Request 1-On-1</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Bonus</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Feedback/Warning</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Change Manager</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Virtual Desktop</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Change Work Hours</v-btn>
                    <v-btn class="menu-btn" prepend-icon="">Office</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 action-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Insights
                    </v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn">Current Status</v-btn>
                    <v-btn class="menu-btn">Timesheet Flag Yesterday</v-btn>
                    <v-btn class="menu-btn">Timesheet Flag Last Week</v-btn>
                    <v-btn class="menu-btn">Contract Ending Soon</v-btn>
                    <v-btn class="menu-btn">Add-On Upsell</v-btn>
                    <v-btn class="menu-btn">Notification</v-btn>
                    <v-btn class="menu-btn">Attention</v-btn>
                    <v-btn class="menu-btn">Issues</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {CommitmentModel} from "shared-components/src/services/openApi/api"
import AddOrEditPositionDetails from "../ProjectPosition/AddOrEditPositionDetails.vue"
import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"
import CommitmentService from "@/services/CommitmentService";
import { UiDashboardProject } from "@/views/Dashboard/Dashboard.vue";
import AppHelper from "shared-components/src/utils/AppHelper";

export default defineComponent({
  components: {
    AddOrEditPositionDetails,
    ProjectSearchWizard
  },
  props: [
    "details",
    "projects",
    "projectId",
    "commitmentId",
  ],
  data() {
    return {
      dialogLoading: false,
      showSearchTeammember: false,
      addLoading: false,
      showUpdatePosition: false,
      showPositionMenuDialog: true,
      selectedProject: null as UiDashboardProject | null,
      selectedCommitment: null as CommitmentModel | null,
    };
  },
  async created() {
    if(this.projectId && this.commitmentId){
      this.selectedCommitment = await CommitmentService.getCommitment(this.commitmentId);
      this.selectedProject = this.projects.find((x: any) => x.id == this.projectId);
    }
  },
  methods: {
    closeDialog() {
      this.$emit("OnClose");
    },
    GetColour(name: any) {
      return AppHelper.GetColour(name);
    },
    GetInitials(name: any) {
      return AppHelper.GetInitials(name);
    },
  },
  computed: {
    getTeammember(){
      if(this.selectedProject){
        return this.selectedProject?.teamMembers.find((x: any) => x.id == (this.selectedCommitment?.TeamMemberIds as any)[0])
      }
      return "-";
    },
    getEpics(){
      if(this.selectedProject?.epics)
      {
        this.selectedProject?.epics.map(x=> x.name).join(", ")
      }
      return "-"
    }
  }
});
</script>
<style lang="scss" scoped>
.info-boxs{
  min-height: 250px;
  padding: 5px
}
.action-boxs{
  min-height: 380px;
}
.menu-action-btns{
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .menu-btn{
    font-size: 12px;
    flex: 45%;
  }
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
  color: white;
}
</style>
